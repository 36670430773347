import React from 'react';
import { search } from '../search/search';
import { filterApplications } from '../search/searchByAreas';

let typingTimer;

class SearchManager extends React.Component {
    constructor(props) {
        super(props);
        this.minSearchLength = 3;
        this.search = this.search.bind(this);
        this.filter = this.filter.bind(this);
        this._searchWorker = this._searchWorker.bind(this);
        this.state = { 
            isLoading: true,
            applicationsToSearch: [],
            searchResults: {
                strong: [],
                weak: []
            }
        };
    }

    search(optionalApplications) {
        if (!this.state.applicationsToSearch && !optionalApplications) return;
        const searchWaitMs = 200;
        this.setState({ isLoading: true })
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => this._searchWorker(optionalApplications), searchWaitMs);
    }

    _searchWorker(optionalApplications) {
        const searchQuery = this.props.makeQuery.get('q');
        search(searchQuery, optionalApplications ? optionalApplications : this.state.applicationsToSearch)
        .then(searchResults => this.setState({searchResults}))
        .then(() => this.setState({ isLoading: false }))
    }

    filter() {
        const searchFilters = {
            ward: this.props.makeQuery.get('ward'),
            conservation_areas: this.props.makeQuery.get('conservation_areas')
        }
        const filteredApplications = filterApplications(searchFilters, this.props.planningApplications);
        this.setState({applicationsToSearch: filteredApplications}, () => {
            this.search();
        });        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.planningApplications === null && this.props.planningApplications !== null) {
            this.setState({applicationsToSearch: this.props.planningApplications});
            this.filter();
            this.setState({ isLoading: false })
        };
    }

    render() {
        const searchManager = {
            search: this.search,
            filter: this.filter,
            applicationsToSearch: this.state.applicationsToSearch,
            results: this.state.searchResults,
            isLoading: this.state.isLoading
        }

        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { 
                  makeQuery: this.props.makeQuery,
                  searchManager
                });
            }
            return child;
          });
        return <>{childrenWithProps}</>;
    }
}

export { SearchManager }