import React from "react";
import "./Results.css";
import { InfiniteScroll } from "../../InfiniteScroll/InfiniteScroll"
import { PlanningApplication } from "../../PlanningApplication/PlanningApplication"

export const Results = React.memo(({results}) => {
    const planningApplications = results.map((result, index) => {
        return (
            <PlanningApplication
                details={result.properties}
                key={result.properties.application_number + index}
            />
        )
    })

    return (
        <div className='results'>
            <InfiniteScroll numberPerPage={10}>
                {planningApplications}
            </InfiniteScroll>
        </div>
    )
})