import { useState } from "react";
import { useSpring, animated } from 'react-spring';
import './Slider.css';

export const Slider = ({title, children, slideHeight, direction}) => {
    const [isActive, setIsActive] = useState(false);

    const sliderHeaderHeight = 42; //sorry, check CSS file and manually update for now

    const sliderTotalHeight = sliderHeaderHeight + slideHeight;

    const springProps = useSpring({ height: isActive ? sliderTotalHeight : sliderHeaderHeight })

    return (
        <div className='sliderContainer'>
            <animated.div style={springProps}>
                <div className={`slider ${isActive ? 'active' : 'inactive'}`}>
                    <button className='sliderHeader' onClick={() => setIsActive(!isActive)} style={{cursor: 'pointer'}}>
                        <h1>{title}</h1>
                        <img src={'/icons/arrow.svg'} className='icon sliderIcon' alt=''/>
                    </button>
                    <div className='sliderContent' style={{height: slideHeight}}>
                        {children}
                    </div>
                </div>
            </animated.div>
        </div> 
    )
}