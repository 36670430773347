import React from 'react';
import './Officer.css';
import { createOfficerEmail } from './helpers.js';

export const Officer = ({details}) => {
    const officerEmail = createOfficerEmail(details);

    return (
        <div className='officer'>
            <span className='officerName'>{details.case_officer}</span>
            <span className='officerEmail'>{officerEmail}</span>
        </div>
    )
}