import { FilterButton } from "../FilterButton/FilterButton";
import { suggestSearch } from "../helpers/suggestSearch";
import "./FilterButtonList.css";

export const FilterButtonList = ({filterSearchTerm, makeQuery, query, searchManager}) => {
    const suggestions = suggestSearch(filterSearchTerm, makeQuery)[query];
    const selections = makeQuery.get(query);

    const createFilterButtons = (array) => array.map(name => {
        return (
            <FilterButton
                searchManager={searchManager}
                makeQuery={makeQuery}
                query={query}
                name={name} 
                key={name+query}
            />
        )
    })

    const selectedFilterButtons = createFilterButtons(selections);
    const suggestedFilterButtons = createFilterButtons(suggestions);

    return (
        <ul className='filterButtons'>
            {selectedFilterButtons.length ? selectedFilterButtons : null}
            {suggestedFilterButtons.length ? suggestedFilterButtons : null}
            {!selectedFilterButtons.length && !suggestedFilterButtons.length ? 'None' : null}
        </ul>
    )
}