import "./Main.css";
import React from 'react';
import { SearchResults } from "../SearchResults/SearchResults";
import { QueryManager } from "./Managers/QueryManager";
import { SearchManager } from "./Managers/SearchManager";
import { Header } from "../Header/Header";
import { Sidebar } from "../Sidebar/Sidebar";

const Content = ({makeQuery, searchManager, deviceType, camdenStatus}) => {
    const searchQuery = makeQuery.get('q');
    return (
        <div className={deviceType} style={{height: '100%'}}>
            <Header makeQuery={makeQuery} searchManager={searchManager}/>
            <main>  
                <Sidebar
                    deviceType={deviceType} 
                    camdenStatus={camdenStatus} 
                    searchManager={searchManager} 
                    makeQuery={makeQuery}
                    searchQuery={searchQuery}
                />
                <div className='maxWidth'>
                    <section id='rightSection'>
                        <SearchResults
                            searchResults={searchManager.results}
                            deviceType={deviceType}
                        />
                    </section>
                </div>
            </main>
        </div>
    )
}

export const Main = ({planningApplicationsList, deviceType, camdenStatus, history}) => {
    return (
        <QueryManager history={history}>
            <SearchManager planningApplications={planningApplicationsList}>
                <Content deviceType={deviceType} camdenStatus={camdenStatus}/>
            </SearchManager>
        </QueryManager>
    )
}