import React, { useState } from 'react';
import { FilterResults } from './FilterResults/FilterResults';
import { FilterSearch } from './FilterSearch/FilterSearch';
import './SearchFilters.css';

export const SearchFilters = ({makeQuery, searchManager}) => {
    const [filterSearchTerm, setFilterSearchTerm] = useState('');

    return (
        <div id='suggestionWidget' className='sidebarWidget'>
            <h1 className='sidebarWidgetTitle'>Filter By Area</h1>
            <div className='sidebarWidgetContent'>
                <FilterSearch filterSearchTerm={filterSearchTerm} setFilterSearchTerm={setFilterSearchTerm}/>
                <FilterResults filterSearchTerm={filterSearchTerm} makeQuery={makeQuery} searchManager={searchManager}/>
            </div>
        </div>
    )
}   