import moment from 'moment';

export const createFormattedDate = (details) => {
    const { decision_date, earliest_decision_date } = details;
    const relevantDate = decision_date === null ? earliest_decision_date : decision_date;
    const relevantDateFormatted = moment(relevantDate).format('dddd Do MMMM YYYY');
    return relevantDateFormatted;
}

export const createFormattedRegisteredDate = (details) => {
    const { registered_date } = details;
    const formattedDate = moment(registered_date).format('dddd Do MMMM YYYY');
    return formattedDate;
}