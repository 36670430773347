import React from 'react';
import spinnerIcon from './spinner.svg';
import tickIcon from './tick.svg';
import './Spinner.css';

export const Spinner = ({isLoading}) => {
    return (
        <>
            {isLoading ? 
            <img className='spinner smallIcon' src={spinnerIcon} alt=''/> : 
            <img className='smallIcon' src={tickIcon} alt=''/>}
        </>
    )
}