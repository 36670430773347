const decisionLevelAcronyms = {
    'Delegated': 'Delegated',
    'Withdrawn': 'Delegated',
    'Withdrawn-Council': 'Delegated',
    'Members Briefing': "Members' Briefing",
    'Development Control Committee': 'Planning Committee'
}

export const getDecisionLevelAcronym = (details) => {
    //note: 'decison_level' is a spelling error at API endpoint
    const decisionLevelAcronym = decisionLevelAcronyms[details.decison_level];
    return decisionLevelAcronym;
}