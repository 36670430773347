import { wards, conservationAreas } from '../data/suggestions.json';

export const suggestSearch = (searchTerm, makeQuery) => {
    const suggestedWards = [];
    const suggestedConservationAreas = [];

    const selectedWards = makeQuery.get('ward');
    const selectedConservationAreas = makeQuery.get('conservation_areas');

    if (!searchTerm.length) return {ward: suggestedWards, conservation_areas: suggestedConservationAreas};

    const searchTermStandardised = searchTerm.toLowerCase().replace(`'`, '');

    wards.forEach(ward => {
        if (selectedWards.includes(ward)) return;
        const wardStandardised = ward.toLowerCase().replace(`'`, '');
        if (wardStandardised.includes(searchTermStandardised)) {
            suggestedWards.push(ward);
        }
    })
    conservationAreas.forEach(conservationArea => {
        if (selectedConservationAreas.includes(conservationArea)) return;
        const conservationAreaStandardised = conservationArea.toLowerCase().replace(`'`, '');
        if (conservationAreaStandardised.includes(searchTermStandardised)) {
            suggestedConservationAreas.push(conservationArea);
        }
    })
    return {ward: suggestedWards, conservation_areas: suggestedConservationAreas};
}