import React, { useState } from 'react';
import { decideOverallStatus, decideStatusClassName } from './helpers';
import './Status.css';

const StatusBar = ({camdenStatus}) => {
    const colouredDivs = camdenStatus.map((isUp, index) => <div key={index} className={decideStatusClassName(isUp)}></div>);
    colouredDivs.reverse();
    const statusBar = <div id='statusBar'>{colouredDivs}</div>
    const statusCircle = <div id='statusCircle' className={decideOverallStatus(camdenStatus)}></div>
    return (
        <div id='status'>
            {statusBar}{statusCircle}
        </div>
    );
}

export const Status = ({camdenStatus}) => {
    const [isActive, setIsActive] = useState(false);

    const statusSpans = {
        'up': <span className='up'>Up</span>,
        'down': <span className='down'>Down</span>,
        'unstable': <span className='unstable'>Unstable</span>,
        'loading': <span className='loading'>Loading</span>,
        'error': <span className='error'>Error</span>,
    }
    const statusDescriptions = {
        'up': <p>You should be able to access documents.</p>,
        'down': <p>You might have trouble accessing documents, but you can still search for summary details above.</p>,
        'unstable': <p>You might have trouble accessing documents, but you can still search for summary details above.</p>,
        'loading': <p>Fetching data from Camden</p>,
        'error': <p>Error. This could be us or Camden</p>
    }
    const overallStatus = decideOverallStatus(camdenStatus);

    return (
        <div id='statusWidget' className='sidebarWidget' onClick={() => setIsActive(!isActive)}>
            <h1 className='sidebarWidgetTitle'>Camden's Internet Connection</h1>
            <div className='sidebarWidgetContent'>
                {statusSpans[overallStatus]}
                <StatusBar camdenStatus={camdenStatus}/>
                {
                    isActive &&
                    statusDescriptions[overallStatus]
                }
                <span id='statusDisclaimer'>Tested from server in EU</span>
            </div>
        </div>
    )
}