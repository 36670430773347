import { getQueries } from "./getQueries";

export async function getRecentApplications() {
    const { openApplications: openApplicationsQuery, pastYear: pastYearQuery } = getQueries();

    const openApplicationsResponse = await fetch(openApplicationsQuery);
    if (!openApplicationsResponse.ok) {
        throw new Error(`Error in calling API for 'Open Applications:' ${openApplicationsResponse.message}`);
    }
    const openApplications = await openApplicationsResponse.json();
    
    const determinedPastYearResponse = await fetch(pastYearQuery);
    if (!determinedPastYearResponse.ok) {
        throw new Error(`Error in calling API for 'Recent Applications': ${determinedPastYearResponse.message}`);
    }
    const determinedPastYear = await determinedPastYearResponse.json();

    return [...determinedPastYear.features, ...openApplications.features];
}