import React from 'react';
import queryString from 'query-string';

class QueryManager extends React.Component {
    constructor(props) {
        super(props);
        this.set = this.set.bind(this);
        this.get = this.get.bind(this);
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        this._parseURL = this._parseURL.bind(this);
        this._writeURL = this._writeURL.bind(this);
    }

    get(query) {
        const queryStringParsed = this._parseURL();
        const queryValue = queryStringParsed[query];
        if (query ==='q') return queryValue;
        if (!queryValue) return [];
        const queryValueArray = (typeof queryValue === 'string') ? [queryValue] : queryValue;
        return queryValueArray;
    }

    set(query, value) {
        this._writeURL(query, value);
    }

    add(query, value) {
        const queryStringParsed = this._parseURL();
        const oldValue = queryStringParsed[query] || [];
        const oldValueArray = (typeof oldValue === 'string') ? [oldValue] : oldValue;
        let newValueArray = [];
        if (oldValueArray.includes(value)) return;
        newValueArray = [...oldValueArray, value];
        this._writeURL(query, newValueArray);
    }

    remove(query, value) {
        const queryStringParsed = this._parseURL();
        const oldValue = queryStringParsed[query];
        const oldValueArray = (typeof oldValue === 'string') ? [oldValue] : oldValue;
        if (!oldValueArray) return;
        if (!oldValueArray.includes(value)) return;
        oldValueArray.splice(oldValueArray.indexOf(value), 1);
        this._writeURL(query, oldValueArray);
    }

    _parseURL() {
        const queryStringParsed = queryString.parse(this.props.history.location.search, {arrayFormat: 'comma'});
        Object.keys(queryStringParsed).forEach(query => {
            if (query==='q') return;
            const valueOfQuery = queryStringParsed[query];
            queryStringParsed[query] = (typeof valueOfQuery === 'string') ? [valueOfQuery] : valueOfQuery;
        })
        return queryStringParsed;
    }

    _writeURL(query, newValue) {
        const state = this._parseURL();
        state[query] = newValue;
        const queryStringToSet = queryString.stringify(state, {arrayFormat: 'comma'});
        this.props.history.push('?' + queryStringToSet);
    }

    render() {
        const makeQuery = {
            get: this.get,
            set: this.set,
            add: this.add,
            remove: this.remove
        }
        const childrenWithProps = React.Children.map(this.props.children, child => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { makeQuery });
            }
            return child;
          });
        return <>{childrenWithProps}</>;
    }
}

export { QueryManager }