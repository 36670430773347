import React, { useRef } from 'react';
import './PlanningApplication.css';
import { Dates } from './Dates/Dates.jsx';
import { Officer } from './Officer/Officer.jsx';
import { Address } from './Address/Address';
import { ButtonBar } from './ButtonBar/ButtonBar.jsx';
import { ApplicationNumber } from './ApplicationNumber/ApplicationNumber';
import { Description } from './Description/Description';
import { Decision } from './Decision/Decision';
import { ApplicationType } from './ApplicationType/ApplicationType';

export const PlanningApplication = ({details}) => {
    const planningApplicationRef = useRef();
    const scrollApplication = () => {
        planningApplicationRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest'})
    }

    return (
        <section className='result' onClick={scrollApplication} ref={planningApplicationRef}>
            <ApplicationType details={details}/>
            <Decision details={details}/>
            <ApplicationNumber details={details}/>
            <Address details={details}/>
            <Dates details={details}/>
            <Officer details={details}/>
            <Description details={details}/>
            <ButtonBar details={details}/>
        </section>
    )
}