const deviceMaxWidthsInPixels = require ('./breakpoints');

export const getDeviceType = () => {
    const windowWidth = window.innerWidth;
    const devices = Object.keys(deviceMaxWidthsInPixels);
    let deviceType;
    devices.forEach(device => {
        if (windowWidth < deviceMaxWidthsInPixels[device] && !deviceType) {
            deviceType = device;
        }
    })
    if (!deviceType) deviceType = 'desktop';
    return deviceType;
}