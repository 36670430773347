import { useState } from 'react';
import { SearchStatus } from '../SearchStatus/SearchStatus';
import './SearchForm.css';

export const SearchForm = ({makeQuery, searchManager}) => {
    const [counter, setCounter] = useState(0);
    const forceUpdate = () => {
        setCounter(counter+1);
    }

    //effects on main handle searching
    const submitForm = (e) => {
        e.preventDefault();
        searchManager.search();
        forceUpdate();
    }

    const changeInput = (e) => {
        makeQuery.set('q', e.target.value)
        searchManager.search();
        forceUpdate();
    }

    return (
        <div id='searchForm'>
            <form onSubmit={submitForm} id='planningApplicationsForm'>
                <input 
                    type='text' 
                    id ='planningApplicationsInput' 
                    value={makeQuery.get('q') ? makeQuery.get('q') : '' } 
                    onChange={changeInput}
                    spellCheck={false}
                    placeholder={"Search"}
                    autoComplete={"off"}
                />
            </form>
            <SearchStatus isLoading={searchManager.isLoading}/>
        </div>
    )
}