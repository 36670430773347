import "./FilterResults.css";
import { FilterButtonList } from "../FilterButtonList/FilterButtonList";

export const FilterResults = ({filterSearchTerm, makeQuery, searchManager}) => {
    const selectedWards = makeQuery.get('ward');
    const selectedConservationAreas = makeQuery.get('conservation_areas');

    return (
            (filterSearchTerm || selectedWards.length || selectedConservationAreas.length) ?
            <>
                <h3>Wards</h3>
                <FilterButtonList
                    filterSearchTerm={filterSearchTerm}
                    makeQuery={makeQuery}
                    query={'ward'}
                    searchManager={searchManager}
                />
                <h3>Conservation Areas</h3>
                <FilterButtonList
                    filterSearchTerm={filterSearchTerm}
                    makeQuery={makeQuery}
                    query={'conservation_areas'}
                    searchManager={searchManager}
                />
            </>
            :
            <span>Currently searching all of Camden</span>
    )
}