const decisionLevelClasses = {
    'Delegated': 'low',
    'Withdrawn-Council': 'low',
    'Withdrawn': 'low',
    'Members Briefing': 'medium',
    'Development Control Committee': 'high'
}

export const getDecisionLevelClass = (details) => {
    const decisionLevelClass = decisionLevelClasses[details.decison_level];
    return decisionLevelClass;
}
