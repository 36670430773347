export const createOfficerEmail = (details) => {
    const officerName = details.case_officer;
    //escape for edge cases
    if (officerName === 'Duty Planner') return 'planning@camden.gov.uk';
    if (officerName === 'Tree Allocation') return 'planning@camden.gov.uk';
    if (officerName === 'Fast Track Team') return 'planning@camden.gov.uk';

    const lowerCaseOfficerName = officerName.toLowerCase();
    const emailAddressNamePart = lowerCaseOfficerName.replace(' ', '.');
    const fullEmailAddress = emailAddressNamePart + '@camden.gov.uk';
    return fullEmailAddress;
}