import { parseQuery } from "../../Main/search/parseQuery";

export const openCamdenDocuments = ({application_number}) => {
    window.open(`http://camdocs.camden.gov.uk/HPRMWebDrawer/PlanRec?q=recContainer:%22${application_number}%22`)
}

export const commentOnApplication = ({comment}) => {
    if (comment) {
        window.open(comment);
    }
}

export const searchOnGoogleMaps = ({development_address}) => {
    const encodedSearchQuery = development_address.replace(" ", "+");
    window.open(`https://google.co.uk/maps/search/${encodedSearchQuery}/@51.5510561,-0.1800021`);
}

export const searchOnGoogleEarth = ({development_address}) => {
    const encodedSearchQuery = development_address.replace(" ", "+");
    window.open(`https://earth.google.com/web/search/${encodedSearchQuery}`);
}

export const searchOnPhotoArchive = ({development_address}) => {
    const { streets } = parseQuery(development_address);
    const firstStreet = streets[0];
    const encodedSearchQuery = firstStreet.replace(" ", "%20");
    window.open(`https://www.londonpicturearchive.org.uk/quick-search?q=${encodedSearchQuery}`);
}