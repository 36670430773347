export const decideOverallStatus = (statusLog) => {
    if (statusLog.length === 0) {return 'loading'}
    //to cope with edge case where there is an empty or undefined value
    if (statusLog.every(status => status !== false)) return 'up';
    if (statusLog.every(status => status !== true)) return 'down';
    if (statusLog.some(status => status === true) && statusLog.some(status => status === false)) return 'unstable';
    return 'error';
}

export const decideStatusClassName = (status) => {
    if (status === true) return 'colored up';
    if (status === false) return 'colored down';
    return 'colored null';
}