export const checkCamden = () => {
    return new Promise((resolve) => {
        fetch('https://check-camden-status-two.herokuapp.com/')
        .then(response => {
            response.json()
            .then(data => resolve(data));
        })
        .catch(error => {
            resolve([null]);
        })
    })
}

export const waitTime = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
}

export const watchCamden = (statusLog) => {
    checkCamden()
    .then(response => {
        response.json()
        .then((data) => {
            statusLog = data;
        })
        waitTime(1000)
        .then(() => {watchCamden(statusLog)});
    })
}

export const pingCamden = () => {
    return new Promise((resolve, reject) => {
        fetch('https://camdocs.camden.gov.uk/HPRMWebDrawer/PlanRec', {
        mode: 'no-cors'
        })
        .then(() => resolve(true))
        .catch(() => resolve(false))
    })
}