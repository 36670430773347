const decisionTypeClasses = {
    'Granted': 'approved',
    'No Objection to Emergency Works (CA)': 'approved',
    'No Objection to Works to Tree(s) in CA': 'approved',
    'Granted Subject to a Section 106 Legal Agreement': 'approved',
    'Prior Approval Required - Approval Given': 'approved',
    'Refused and Warning of Enforcement Action to be Taken': 'rejected',
    'Withdrawn Decision': 'rejected',
    'Refused': 'rejected',
    'Prior Approval Required - Approval Refused': 'rejected',
    'Approve Works (TPO)': 'approved',
    'No objection': 'approved',
    'Grant Prior Approval': 'approved',
    'Granted Prior approval subject to Section 106 Legal agreement': 'approved',
    'Approve Emergency Works (TPO)': 'approved',
    'Part Granted/Refused': 'mixed',
    'Notified': 'approved',
    'Objection to Works to Tree(s) in a CA': 'rejected',
    'Refuse Works (TPO)': 'rejected'
}

export const getDecisionTypeClass = (details) => {
    return decisionTypeClasses[details.decision_type];
}