import { OverlayScrollbarsComponent } from "overlayscrollbars-react"

export const ScrollManager = ({children}) => {
    const deviceType = document.body.className;
    //if mobile, then we forget using the temperamental 'overlayscrollbars component'

    if (deviceType === 'mobile') {
        return children;
    } else {
        return <OverlayScrollbarsComponent>{children}</OverlayScrollbarsComponent>
    }
}