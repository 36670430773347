const clean = (string) => {
    return string.replace(`'`, '').toLowerCase();
}

const getRelevantApplications = (queryType, queryName, planningApplications) => {
    let results = [];
    planningApplications.forEach(application => {
        const applicationProp = application.properties[queryType];
        if (applicationProp===null) return;
        if (clean(applicationProp).includes(clean(queryName))) results.push(application);
    })
    return results;
}


export const filterApplications = (filters, planningApplications) => {
    const {conservation_areas, ward} = filters;
    let results = [];

    if (!conservation_areas.length && !ward.length) {
        results = planningApplications;
        return results;
    }

    if (conservation_areas.length) {
        conservation_areas.forEach(conservationArea => {
            const relevantApplications = getRelevantApplications('conservation_areas', conservationArea, planningApplications);
            results = [...results, ...relevantApplications];
        })
    }
    if (ward.length) {
        ward.forEach(wardName => {
            const relevantApplications = getRelevantApplications('ward', wardName, planningApplications);
            results = [...results, ...relevantApplications];
        })
    }
    results.sort(function(a,b) {
        return new Date(b.properties.registered_date) - new Date(a.properties.registered_date);
    });
    return results;
}