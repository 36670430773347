export const subtractNumOfMonths = (date, numOfMonths) => {
    const dateToMutate = new Date(date);
    dateToMutate.setMonth(dateToMutate.getMonth() - numOfMonths);
    //above returns ISO timestamp
    const dateNumMonthsAgo = new Date(dateToMutate);
    return dateNumMonthsAgo;
}

export const convertDateToPassableString = (date) => {
    const dateISOFormat = date.toISOString();
    const dateCorrectFormat = dateISOFormat.slice(0, dateISOFormat.length-1)
    return dateCorrectFormat;
}