import './Decision.css';
import { getDecisionLevelAcronym } from "./helpers/getDecisionLevelAcronym";
import { getDecisionTypeClass } from "./helpers/getDecisionTypeClass";
import { getDecisionLevelClass } from "./helpers/getDecisionLevelClass";

export const Decision = ({details}) => {
    const { decision_type } = details;
    
    //depending on whether application is open or determined
    //decision_type === null when Camden application is open (determined by API)

    if (decision_type === null) {
        return (
            <div className='decision openApplication'>
                <span className='decisionType'>Open</span>
            </div>
        )
    }

    //application has been determined

    return (
        <div className={`decision ${getDecisionTypeClass(details)}`}>
            <span className='decisionType'>{decision_type}</span>
            <div className='decisionLevelAcronym'>
                <span className={getDecisionLevelClass(details)}>{getDecisionLevelAcronym(details)}</span>
            </div>
        </div>
    )
}