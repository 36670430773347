import { useEffect, useState } from "react";
import './InfiniteScroll.css';

export const InfiniteScroll = ({children, numberPerPage}) => {
    const childrenSignature = JSON.stringify(children.map(child => child.key));

    const [numberToRender, setNumberToRender] = useState(numberPerPage);

    const getMore = () => {
        setNumberToRender(numberToRender + numberPerPage);
    }

    useEffect(() => {
        setNumberToRender(numberPerPage);
    }, [childrenSignature, numberPerPage, setNumberToRender]);

    if (children.length===0) return null;
    return (
        <>
            <div className='infiniteScroll'>
                {children.slice(0, numberToRender)}
                {
                    !(numberToRender >= children.length) &&
                    <button className='loadMoreButton iconButton' onClick={getMore}>Load more...</button>
                } 
            </div>
        </>
    )
}