import "./ButtonBar.css";
import { Button } from "../../Button/Button";
import { commentOnApplication, openCamdenDocuments, searchOnGoogleEarth, searchOnGoogleMaps, searchOnPhotoArchive } from "./helpers";

export const ButtonBar = ({details}) => {
    const camdenButton = <Button icon={'/icons/camden.svg'} text={'Documents'} action={() => openCamdenDocuments(details)} color='rgba(0,0,0,0)'/>
    const commentButton = <Button icon={'/icons/writing.svg'} text={'Comment'} action={() => commentOnApplication(details)} color='rgba(0,0,0,0)'/>
    const googleMapsButton = <Button icon={'/icons/googleMaps.svg'} text={'Google Maps'} action={() => searchOnGoogleMaps(details)} color='rgba(0,0,0,0)'/>
    const googleEarthButton = <Button icon={'/icons/googleEarth.svg'} text={'Google Earth'} action={() => searchOnGoogleEarth(details)} color='rgba(0,0,0,0)'/>
    const photoArchiveButton = <Button icon={'/icons/cityOfLondon.svg'} text={'Photo Archive'} action={() => searchOnPhotoArchive(details)} color='rgba(0,0,0,0)'/>

    return (
        <div className='planningApplicationButtons'>
            {camdenButton}
            {details.decision_date===null && commentButton}
            {googleMapsButton}
            {googleEarthButton}
            {photoArchiveButton}
        </div>
    )

}