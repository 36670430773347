import { Button } from "../../Button/Button";
import './FilterButton.css';

export const FilterButton = ({name, makeQuery, query, searchManager}) => {
    const allSelected = makeQuery.get(query);
    const buttonIsSelected = allSelected.includes(name);

    const buttonAction = () => {
        buttonIsSelected ? makeQuery.remove(query, name) : makeQuery.add(query, name);
        searchManager.filter();
    }

    const buttonColor = buttonIsSelected ? 'green' : 'grey';

    return (
        <Button 
            key={name} 
            text={name} 
            action={buttonAction}
            color={buttonColor}
        />
    )
}
