import React from 'react';
import { Main } from '../Main/Main';
import { getDeviceType } from './getDeviceType.js/getDeviceType';
import { getRecentApplications } from './getPlanningApps/getPlanningApps';
import { checkCamden, waitTime } from './checkCamdenStatus/checkCamdenStatus';
import { BrowserRouter, Route } from 'react-router-dom';

class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        planningApplications: null,
        deviceType: getDeviceType(),
        camdenStatusLog: []
      }
      window.onresize = () => {this.setState({deviceType: getDeviceType()})};
    }

    async componentDidMount() {
      document.body.className = this.state.deviceType;

      getRecentApplications()
      .then((applications) => {
        this.setState({planningApplications: applications})
      })
      .catch((err) => {
        this.setState({planningApplications: "failed"})
        console.log(err);
      })
      this.watchCamden();
    }

    watchCamden() {
      const waitTimeMs = 9000;
      checkCamden()
      .then(response => {
        this.setState({camdenStatusLog: response})
        waitTime(waitTimeMs)
        .then(() => {this.watchCamden()})
     })
    }

    componentDidUpdate() {
      document.body.className = this.state.deviceType; 
    }

    render() {
        return (
          <BrowserRouter>
            <Route path='/' render={({history}) => {
              return (
                <Main
                history={history}
                planningApplicationsList={this.state.planningApplications} 
                deviceType={this.state.deviceType}
                camdenStatus={this.state.camdenStatusLog}
              />     
              )
            }
            }>
            </Route>
          </BrowserRouter>
        )
    }
}

export default App;