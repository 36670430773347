import React from 'react';
import './SearchResults.css';
import { Results } from './Results/Results';
import { ScrollManager } from '../ScrollManager/ScrollManager';
import { ResultsHeader } from './ResultsHeader/ResultsHeader';

const checkIfPlanningApplicationOpen = (planningApplication) => {
    const isOpen = (planningApplication.properties.decision_type === null);
    return isOpen;
}

const SearchResultsParallel = ({open, closed}) => {
    const openSubtitle = `Most Recent, ${open.length} results`;
    const closedSubtitle = `Past Year, ${closed.length} results`;

    return (
        <>
            <section id='open' className='resultsColumn'>
                <ResultsHeader title={'Open'} subtitle={openSubtitle}/>
                <ScrollManager>
                    <Results results={open}/>
                </ScrollManager>
            </section>
            <section id='closed' className='resultsColumn'>
                <ResultsHeader title={'Closed'} subtitle={closedSubtitle}/>
                <ScrollManager>
                    <Results results={closed}/>
                </ScrollManager>
            </section>
        </>
    )
}

const SearchResultsSeries = ({open, closed}) => {
    const openSubtitle = `Most Recent, ${open.length} results`;
    const closedSubtitle = `Past Year, ${closed.length} results`;

    return (
        <>
            <ScrollManager>
                <section id='open' className='resultsColumn'>
                    <ResultsHeader title={'Open'} subtitle={openSubtitle}/>
                    <Results results={open}/>
                </section>
                <section id='closed' className='resultsColumn'>
                    <ResultsHeader title={'Closed'} subtitle={closedSubtitle}/>
                    <Results results={closed}/>
                </section>
            </ScrollManager>
        </>
    )
}

export const SearchResults = ({deviceType, searchResults}) => {
    let open = [];
    let closed = [];

    if (searchResults) {
        const { strong, weak } = searchResults;
        strong.forEach(result => checkIfPlanningApplicationOpen(result) ? open.push(result) : closed.push(result));
        weak.forEach(result => checkIfPlanningApplicationOpen(result) ? open.push(result) : closed.push(result));
        //ensures weak results come last
    }

    if (deviceType==='desktop') {
        return <SearchResultsParallel open={open} closed={closed}/>
    } else {
        return <SearchResultsSeries open={open} closed={closed}/>
    }
}