import React from 'react';
import './Dates.css';
import { createFormattedDate, createFormattedRegisteredDate } from './helpers.js';

export const Dates = ({details}) => {
    const date = createFormattedDate(details);
    const registeredDate = createFormattedRegisteredDate(details);

    return (
        <div className='datesSection'>
            <div>
                <span className='dateDescription'>Registered</span>
                <span className='date'>{registeredDate}</span>
            </div>
            <div>
                <span className='dateDescription'>{details.decision_date===null ? 'Determination Date' : 'Determined'}</span>
                <span className='date'>{date}</span>
            </div>
        </div>
    )
}