import './Sidebar.css';
import { Slider } from "../Slider/Slider";
import { Status } from "./Status/Status";
import React from 'react';
import { ScrollManager } from '../ScrollManager/ScrollManager';
import { SearchFilters } from '../SearchFilters/SearchFilters';

const SidebarMobile = ({camdenStatus, makeQuery, searchManager}) => {
    const wardFilters = makeQuery.get('ward');
    const conservationAreaFilters = makeQuery.get('conservation_areas');
    const filterNumber = wardFilters.length + conservationAreaFilters.length;
    const title = `Filters by Area (${filterNumber})`;

    return (
        <Slider title={title} slideHeight ={400} direction={'up'}>
            <SidebarNormal camdenStatus={camdenStatus} makeQuery={makeQuery} searchManager={searchManager}/>
        </Slider>
    )
}

const SidebarNormal = ({camdenStatus, makeQuery, searchManager}) => {
    return (
        <div id='sidebar' className='leftContainer'>
            <ScrollManager>
                <div id='searchWidgets'>
                    <Status camdenStatus={camdenStatus}/>
                    <SearchFilters makeQuery={makeQuery} searchManager={searchManager}/>
                </div>
            </ScrollManager>
        </div>
    )     
}

export const Sidebar = ({deviceType, makeQuery, searchManager, camdenStatus}) => {
    if (deviceType === 'mobile') {
        return (
            <SidebarMobile 
                camdenStatus={camdenStatus} 
                makeQuery={makeQuery} 
                searchManager={searchManager}
            />
        )
    } else {
        return (
            <SidebarNormal 
                camdenStatus={camdenStatus} 
                makeQuery={makeQuery} 
                searchManager={searchManager}
            />
        )
    }
}