import { convertDateToPassableString, subtractNumOfMonths } from "./helpers";

const areaOfInterestPolygon = [
    '-0.4627991 51.3503429',
    '0.3680420 51.4317518',
    '0.2307129 51.8103129',
    '-0.6262207 51.7015021',
    '-0.4696655 51.3563464',
    '-0.4627991 51.3503429'
];

export const getApiEndpoint = () => {
    //API requires polygon coordinates to 'join back up' at the end
    const polygonCoordinatesCorrectFormat = `${areaOfInterestPolygon.join(', ')}, ${areaOfInterestPolygon[0]}`;

    const polygonFunction = `'POLYGON((${polygonCoordinatesCorrectFormat}))'`
    const polygonQueryString = `within_polygon(location, ${polygonFunction})`

    const apiEndpoint = `https://opendata.camden.gov.uk/resource/2eiu-s2cw.geojson?$WHERE=${polygonQueryString}`;

    return apiEndpoint;
}

const getDates = () => {
    const todayDate = new Date();
    const dates = {
        today: todayDate,
        threeMonthsAgo: subtractNumOfMonths(todayDate, 3),
        twelveMonthsAgo: subtractNumOfMonths(todayDate, 12)
    }
    const datesAsQueries = {};
    Object.keys(dates).forEach(date => datesAsQueries[date] = convertDateToPassableString(dates[date]));
    return datesAsQueries;
}

export const getQueries = () => {
    const {today, ...pastDates} = getDates();
    const queryStrings = {
        openApplication: `system_status=Registered`,
        unlimited: `$limit=3000000000`,
        determinedPastThreeMonths: `decision_date between '${pastDates.threeMonthsAgo}' and '${today}'`,
        determinedPastYear: `decision_date between '${pastDates.twelveMonthsAgo}' and '${today}'`,
    }
    const apiEndpoint = getApiEndpoint();
    const queries = {
        openApplications: `${apiEndpoint}&${queryStrings.openApplication}&${queryStrings.unlimited}`,
        pastThreeMonths: `${apiEndpoint}and ${queryStrings.determinedPastThreeMonths}&${queryStrings.unlimited}`,
        pastYear: `${apiEndpoint}and ${queryStrings.determinedPastYear}&${queryStrings.unlimited}`,
    }

    return queries;
}




