import "./FilterSearch.css";

export const FilterSearch = ({filterSearchTerm, setFilterSearchTerm}) => {
    return (
        <input 
            id='filterResultsInput'
            placeholder='Wards and Conservation Areas'
            value={filterSearchTerm}
            onChange={e => setFilterSearchTerm(e.target.value)}
            autoComplete={"off"}
            spellCheck={'false'}
        >
        </input>
    )
}