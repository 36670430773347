import React from 'react';
import './Header.css';
import { SearchForm } from '../SearchForm/SearchForm';

export const Header = ({makeQuery, searchManager}) => {
    return (
        <header id='pageHeader'>
            <div id='headerLogoAndText' className='leftContainer'>
                <img id='headerLogo' src='/logos/logoWhite.svg' alt=''></img>
                <div id='headerText'>
                    <h1>Camden Planning Search</h1>
                    <a id='headerLink' href='https://bloomsburyconservation.org.uk' target='_blank' rel='noreferrer'>Bloomsbury Conservation</a>
                </div>
            </div>
            <SearchForm makeQuery={makeQuery} searchManager={searchManager}/>
        </header>
    )
}